import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Tabs } from '@sumup/circuit-ui';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import { SIDEBAR_WIDTH } from 'components/SidebarComponent/SidebarService';
import EMVConfigurations from '../EMVConfigurations';
import TextFiles from '../TextFiles';
import Firmware from '../Firmware';
import ReaderAvailability from '../ReaderAvailability';

const TabContent = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: calc(100% - ${SIDEBAR_WIDTH});
    padding: 0 ${theme.spacings.zetta};
    left: 0;

    @media (min-width: 960px) {
      left: ${SIDEBAR_WIDTH};
    }
  `};
`;

const ReaderConfigurations = ({ t: translate, location }) => {
  const [activeTab, setActiveTab] = useState('EMVConfigurations');

  const tabNavigation = [
    {
      id: 'emvConfigurations',
      tab: 'EMV Configurations',
      panel: (
        <TabContent>
          <EMVConfigurations translate={translate} location={location} />
        </TabContent>
      )
    },
    {
      id: 'textFiles',
      tab: 'Text Files',
      panel: (
        <TabContent>
          <TextFiles
            translate={translate}
            location={location}
            active={activeTab === 'textFiles'}
          />
        </TabContent>
      )
    },

    {
      id: 'firmware',
      tab: 'Firmware',
      panel: (
        <TabContent>
          <Firmware
            translate={translate}
            location={location}
            active={activeTab === 'firmware'}
          />
        </TabContent>
      )
    }
  ];
  if (['solo', 'solo_ul'].includes(location.state.path)) {
    tabNavigation.push({
      id: 'readerAvailability',
      tab: 'Reader Availability',
      panel: (
        <TabContent>
          <ReaderAvailability translate={translate} location={location} />
        </TabContent>
      )
    });
  }

  const setActive = () => {
    tabNavigation.forEach(item => {
      const tab = document.getElementById(`tab-${item.id}`);
      tab.onclick = () => setActiveTab(item.id);
    });
  };

  useEffect(() => setActive(), []);

  return (
    <PageContainer>
      <PageHeader noPadding titleKey={'cardReadersConfiguration.pageTitle'} />

      <Tabs
        items={tabNavigation}
        data-testid="tabNavigation"
        style={{
          width: '100%',
          boxShadow: '0 0 0',
          borderBottom: '1px solid #ccc'
        }}
      />
    </PageContainer>
  );
};

ReaderConfigurations.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

export default ReaderConfigurations;
