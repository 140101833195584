import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { ROUTES_PATH } from 'constants/routes';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Pagination, useModal } from '@sumup/circuit-ui';
import { Error as ErrorIcon } from 'assets/icons';
import MainContent from 'components/MainContent';
import Loading from 'components/Loading';
import DefaultSection from 'components/DefaultSection';
import PageContainer from 'components/PageContainer';
import PageHeader from 'components/PageHeader';
import ReaderImageCard from '../ReaderImageCard';
import ConfirmationModal from '../UploadCarrierSuggestion/components/ConfirmationModal';
import { calculatePaginationItens } from '../../services/PaginationService';
import { CARD_READERS_MAP } from '../CardReadersList/CardReadersListService';

import {
  getConfigurationFiles,
  shouldRenderTable,
  filterByCountry,
  targetConfigurationFile
} from './TestConfigurationService';

const Container = styled.div`
  display: flex;
  margin-top: 1.5em;
`;

const ContentContainer = styled.div`
  flex-grow: 2;
`;

const TableContainer = styled.div`
  & tr:hover {
    opacity: 0.65;
    transition: 0.35s ease-out;
  }
`;

const StyledPagination = styled(Pagination)`
  justify-content: end;

  & button,
  & button:hover {
    color: black;
    background-color: transparent;
    border-color: transparent;
  }
  & button:focus {
    box-shadow: 0 0 0 4px #ddd;
  }
  & select:focus {
    box-shadow: 0 0 0 2px #ddd;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
`;

const ErrorSectionContainer = styled.div`
  width: 100%;
  margin: auto;

  & > div {
    max-width: 100%;
  }
  & div > svg {
    width: 66px;
    height: 68px;
  }
`;

const TestConfigurationList = ({ t: translate }) => {
  const [loadingConfigurations, setLoadingConfigurations] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [configurations, setConfigurations] = useState([]);
  const [filterValue, setFilterValue] = useState(false);
  const [paginationContent, setPaginationContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { setModal, removeModal } = useModal();
  // const [listConfigurations, setListConfigurations] = useState(true);

  const readerName = CARD_READERS_MAP.solo.name;
  const readerPath = CARD_READERS_MAP.solo.url;
  const allResultsOption = { label: 'All', value: 'ALL' };

  const FormModal = styled.div`
    ${({ theme }) => css`
      padding: ${theme.spacings.giga};
    `};
  `;

  const getConfigurations = () => {
    setLoadingConfigurations(true);

    getConfigurationFiles(readerPath)
      .then(response => (response.ok ? response.json() : setLoadingError(true)))
      .then(response => {
        setConfigurations(response);
        calculatePaginationItens(response, setPaginationContent);
      })
      .catch(() => setLoadingError(true))
      .finally(() => setLoadingConfigurations(false));
  };

  const targetConfigurationModal = id => (
    <FormModal id="response-modal">
      <ConfirmationModal
        action={() => {
          targetConfigurationFile(id).then(response => {
            if (response.ok) {
              removeModal(targetConfigurationModal());
              getConfigurations();
            }
            return response;
          });
        }}
        close={() => removeModal(targetConfigurationModal())}
        translate={translate}
        text="The device will fetch selected configuration."
      />
    </FormModal>
  );

  const showTargetConfigurationModal = (id, isTargeted) =>
    !isTargeted &&
    setModal({
      children: targetConfigurationModal(id),
      variant: 'immersive',
      closeButtonLabel: 'Close modal'
    });

  const renderConfigurationsTable = () => {
    if (paginationContent && paginationContent[currentPage]) {
      return shouldRenderTable(
        paginationContent[currentPage],
        getConfigurations,
        showTargetConfigurationModal
      );
    }
    return false;
  };

  const shouldShowPagination = paginationContent.length > 1;

  const filterResults = filter => {
    // eslint-disable-next-line no-unused-expressions
    filter === allResultsOption.value
      ? calculatePaginationItens(configurations, setPaginationContent)
      : calculatePaginationItens(
          filterByCountry(filter, configurations),
          setPaginationContent
        );

    setFilterValue(filter);
    return setCurrentPage(1);
  };

  useEffect(() => {
    const controller = new AbortController();
    getConfigurations();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    filterResults(filterValue);
  }, [filterValue]);

  useEffect(() => {
    if (!readerName && !readerPath) {
      navigate(ROUTES_PATH.CARD_READERS_CONFIGURATION);
    }
  }, []);

  return (
    <>
      <PageContainer>
        <PageHeader noPadding titleKey="Giro Test Configuration" />

        {loadingConfigurations ? (
          <Loading />
        ) : (
          <>
            <MainContent>
              <Container>
                <ReaderImageCard
                  location={{
                    state: {
                      name: CARD_READERS_MAP.solo.name,
                      image: CARD_READERS_MAP.solo.image
                    }
                  }}
                />

                <ContentContainer>
                  {loadingError ? (
                    <ErrorSectionContainer>
                      <DefaultSection
                        sectionImage={<ErrorIcon />}
                        sectionTitle={translate('genericError.title')}
                        sectionText={translate('genericError.text')}
                        titleSize="one"
                      />
                    </ErrorSectionContainer>
                  ) : (
                    <TableContainer>
                      {renderConfigurationsTable()}
                    </TableContainer>
                  )}
                </ContentContainer>
              </Container>
            </MainContent>

            <NavigationContainer>
              {shouldShowPagination && (
                <StyledPagination
                  data-testid="pagination"
                  label="Countries"
                  previousLabel="Previous page"
                  nextLabel="Next page"
                  onChange={page => setCurrentPage(page)}
                  currentPage={currentPage}
                  totalPages={paginationContent.length - 1}
                />
              )}
            </NavigationContainer>
          </>
        )}
      </PageContainer>
    </>
  );
};

TestConfigurationList.propTypes = {
  t: PropTypes.func.isRequired
};

export default TestConfigurationList;
