import React from 'react';
import ImageSumUp3g from 'assets/imgs/reader-3g.png';
import ImageSumUpAndroidTerminal from 'assets/imgs/reader-android-terminal.png';
import ImageSumUpAir from 'assets/imgs/reader-air.png';
import ImageSumUpAirLite from 'assets/imgs/reader-air-lite.png';
import ImageSumUpPinPlus from 'assets/imgs/reader-pinplus.png';
import ImageSumUpPinPlusContactless from 'assets/imgs/reader-pinplus-contactless.png';
import ImageSumUpSolo from 'assets/imgs/reader-solo.png';
import ImageSumUpUTOPIA from 'assets/imgs/reader-utopia.png';
import { ImageCardReader } from 'assets/icons';

export const getCardReaders = () =>
  fetch('/assets_management_api/devices?with_assets%5B%5D=emv_config');

export const CARD_READERS_MAP = {
  air_lite: {
    image: ImageSumUpAirLite,
    name: 'Air Lite',
    url: 'air_lite'
  },
  air: {
    image: ImageSumUpAir,
    name: 'Air',
    url: 'air'
  },
  pinplus_lite: {
    image: ImageSumUpPinPlus,
    name: 'Pin+ Lite',
    url: 'pinplus_lite'
  },
  pinplus_contactless: {
    image: ImageSumUpPinPlusContactless,
    name: 'Pin+ Contactless',
    url: 'pinplus_contactless'
  },
  solo: {
    image: ImageSumUpSolo,
    name: 'Solo',
    url: 'solo'
  },
  solo_ul: {
    image: ImageSumUpSolo,
    name: 'Solo UL',
    url: 'solo_ul'
  },
  android_terminal: {
    image: ImageSumUpAndroidTerminal,
    name: 'Android Terminal',
    url: 'android_terminal'
  },
  '3g': {
    image: ImageSumUp3g,
    name: '3G',
    url: '3g'
  },
  utopia: {
    image: ImageSumUpUTOPIA,
    name: 'UTOPIA',
    url: 'utopia'
  },
  DEFAULT: {
    image: <ImageCardReader />,
    name: 'resultsInfo.model.empty.label'
  }
};
