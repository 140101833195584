import React from 'react';
import { Table } from '@sumup/circuit-ui';
import { IconEye } from 'assets/icons';
import { formatDateTimeToShow } from 'services/DateTimeFormatService';

export const getConfigurationFiles = reader =>
  fetch(`/assets_management_api/devices/${reader}/test_configurations`);

export const targetConfigurationFile = id => {
  const options = {
    method: 'PUT'
  };
  return fetch(
    `/assets_management_api/test_configurations/${id}/set_targeted`,
    options
  );
};

const getFlagEmoji = countryCode => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

const renderPublishedButton = (id, isTargeted, targetConfigurationModal) => {
  if (isTargeted === undefined || isTargeted === null) {
    return '';
  }
  if (isTargeted) {
    return (
      <IconEye
        style={{
          alignSelf: 'center',
          maxHeight: '48px',
          opacity: '1',
          color: 'green'
        }}
      />
    );
  }
  return (
    <IconEye
      style={{
        alignSelf: 'center',
        maxHeight: '48px',
        opacity: '.1'
      }}
      onClick={() => targetConfigurationModal(id)}
    />
  );
};

export const mapConfigurations = (row, targetConfigurationModal) => [
  {
    children: renderPublishedButton(
      row.id,
      row.targeted,
      targetConfigurationModal
    )
  },
  { children: `${getFlagEmoji(row.country)} ${row.country}` },
  { children: row.tx_type },
  { children: row.version },
  { children: row.optional_remainder },
  { children: formatDateTimeToShow(row.updated_at) }
];

export const mapCountriesAvailable = data => {
  const countryCodes = [];
  return data
    .filter(
      item =>
        !countryCodes.includes(item.country) &&
        countryCodes.push(item.country) &&
        item
    )
    .map(item => ({
      label: item.country,
      value: item.country
    }))
    .sort((prevItem, nextItem) => prevItem.label.localeCompare(nextItem.label));
};

export const shouldRenderTable = (data, callback, targetConfigurationModal) => {
  return data ? (
    <Table
      noShadow
      headers={[
        {
          children: 'Targeted'
        },
        {
          children: 'Country'
        },
        {
          children: 'Interface'
        },
        {
          children: 'Version'
        },
        {
          children: 'Optional Remainder'
        },
        {
          children: 'Updated'
        }
      ]}
      rows={data.map(row => mapConfigurations(row, targetConfigurationModal))}
    />
  ) : (
    callback()
  );
};

export const filterByCountry = (countryCode, entries) =>
  entries.filter(item => item.country === countryCode);
