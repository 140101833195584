export const ROUTES_PATH = {
  SERIAL_NUMBER_SEARCH: {
    ROOT: '/',
    SEARCH: '/search'
  },
  CARRIER_SUGGESTION: '/carrier-suggestion',
  UPLOAD_CARRIER_SUGGESTION: '/carrier-suggestion/upload',
  UPLOAD_SIM_CARDS: '/upload-sim-cards',
  REVERSE_LOGISTICS_FILE_CONVERTER: '/reverse-logistics-file-converter',
  SIM_REACTIVATION_REQUESTS: '/sim-reactivation-requests',
  CARD_READERS_CONFIGURATION: '/readers-configuration',
  HOME: '/',
  LOGIN: '/login',
  TEST_CONFIGURATIONS: '/test-configurations'
};
